import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { UseComboboxGetItemPropsOptions } from 'downshift';
import { getSvg, renderSuggestedItem } from './helpers';
import Suggestion from '../../../types/Suggestion';
import { LocationAutocompleteVariant } from './index';
import CountryCode from '../../../types/CountryCode';
import useDynamicIosViewportHeight from '../../../hooks/useDynamicIosViewportHeight';
import useHasScrolledPast from '../../../hooks/useHasScrolledPast';
import Heading from '@afs/components/Heading';
import Svg from '../../atoms/Svg';
import styles from './styles.module.scss';
type PageVariant = {
  isSearchPage: boolean;
  isHomePageMobile: boolean;
  isHomePageDesktop: boolean;
  isForm: boolean;
};
export const renderDropdownContent = (searchTerm: string, options: Suggestion[], highlightedIndex: number, getItemProps: (options: UseComboboxGetItemPropsOptions<Suggestion>) => any, variant: LocationAutocompleteVariant, countryCodeForAutocomplete: CountryCode, noMatches?: boolean, hasAutocompleteSuggestions?: boolean, error?: boolean, geoCoordinatesError?: boolean) => {
  const isSearchPage = variant === LocationAutocompleteVariant.SEARCH;
  const isHomePageMobile = variant === LocationAutocompleteVariant.HPMOBILE;
  const isHomePageDesktop = variant === LocationAutocompleteVariant.HPDESKTOP;
  const isForm = variant === LocationAutocompleteVariant.FORM;
  const isUk = countryCodeForAutocomplete?.toLowerCase() === 'uk' || countryCodeForAutocomplete?.toLowerCase() === 'gb' || countryCodeForAutocomplete === 'afsCountries';
  const apiResults = options.filter((item: Suggestion) => !item.placeId.startsWith('popular'));
  const popularCities = options.filter((item: Suggestion) => item.placeId.startsWith('popular'));
  const isError = noMatches || error || geoCoordinatesError;
  return <MenuContainer isSearchPage={isSearchPage} isHomePageMobile={isHomePageMobile} isHomePageDesktop={isHomePageDesktop} isForm={isForm} isError={isError || false} data-sentry-element="MenuContainer" data-sentry-component="renderDropdownContent" data-sentry-source-file="Components.tsx">
      <>
        {error && <GeneralError />}

        {geoCoordinatesError && <GeoError />}

        {noMatches && searchTerm.length > 3 && <NoMatches searchTerm={searchTerm} />}

        {(!isUk && searchTerm.length < 3 || isUk && searchTerm.length > 0 && searchTerm.length < 3) && <AddMoreLetters searchTermLength={searchTerm.length} />}

        {hasAutocompleteSuggestions && searchTerm && <SearchResults apiResults={apiResults} variant={variant} getItemProps={getItemProps} highlightedIndex={highlightedIndex} searchTerm={searchTerm} />}

        {isUk && !isError && (searchTerm.length === 0 || searchTerm.length >= 3) && <PopularCitiesList popularCities={popularCities} variant={variant} getItemProps={getItemProps} apiResults={apiResults} highlightedIndex={highlightedIndex} />}
      </>
    </MenuContainer>;
};
type MenuContainerProps = {
  children: ReactElement;
  isError: boolean;
} & PageVariant;
export const MenuContainer = ({
  isSearchPage,
  isHomePageMobile,
  isHomePageDesktop,
  isForm,
  isError,
  children
}: MenuContainerProps) => {
  useDynamicIosViewportHeight(); // This need to only run on mobile
  const {
    ref: menuRef,
    hasScrolled
  } = useHasScrolledPast<HTMLDivElement>(5);
  return <div className={classNames(styles.menuContainer, {
    [styles.searchMenu]: isSearchPage,
    [styles.homepageMenuMobile]: isHomePageMobile,
    [styles.homepageMenuDesktop]: isHomePageDesktop || isForm,
    [styles.formMenu]: isForm
  })} data-sentry-component="MenuContainer" data-sentry-source-file="Components.tsx">
      <div className={classNames(styles.scrollShadowContainer, {
      [styles.scrollShadow]: hasScrolled
    })} />
      <div className={styles.menuWrapper} ref={menuRef}>
        {children}
        <GoogleLogo isError={isError} data-sentry-element="GoogleLogo" data-sentry-source-file="Components.tsx" />
      </div>
    </div>;
};
export const GoogleLogo = ({
  isError
}: {
  isError: boolean;
}) => <div className={classNames(styles.googleLogo, {
  [styles.errorMargin]: isError
})} data-sentry-component="GoogleLogo" data-sentry-source-file="Components.tsx">
    <span>powered by</span>
    <Svg type="search" name="google-logo" className={styles.logo} data-sentry-element="Svg" data-sentry-source-file="Components.tsx" />
  </div>;
type PopularCitiesListProps = {
  popularCities: Suggestion[];
  variant: LocationAutocompleteVariant;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Suggestion>) => any;
  apiResults: Suggestion[];
  highlightedIndex: number;
};
export const PopularCitiesList = ({
  popularCities,
  variant,
  getItemProps,
  apiResults,
  highlightedIndex
}: PopularCitiesListProps) => {
  return <>
      <Heading level={2} className={styles.popularCityLinks} data-sentry-element="Heading" data-sentry-source-file="Components.tsx">
        Popular cities
      </Heading>

      <ul className={styles.list}>
        {popularCities.map((item: Suggestion, index: number) => <li data-testid={`popular-city-option-${item.label.mainText}`} className={classNames(styles.listItem, variant, {
        [styles.firstListItem]: index === 0
      })} key={item.placeId} {...getItemProps({
        item,
        index: apiResults.length + index
      })}>
            <span className={classNames(styles.item, {
          [styles.itemHighlighted]: highlightedIndex === index + apiResults.length
        })}>
              <span className={styles.iconWrapper}>
                <Svg type="search" name="city" data-testid={`popular-city-icon-${item.type}-${index}`} />
              </span>
              <span className={styles.popularCitiesText}>
                {item.label.mainText}
              </span>
            </span>
          </li>)}
      </ul>
    </>;
};
type SearchResultsProps = {
  apiResults: Suggestion[];
  variant: LocationAutocompleteVariant;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Suggestion>) => any;
  highlightedIndex: number;
  searchTerm: string;
};
export const SearchResults = ({
  apiResults,
  variant,
  getItemProps,
  highlightedIndex,
  searchTerm
}: SearchResultsProps) => {
  return <ul className={styles.list} data-sentry-component="SearchResults" data-sentry-source-file="Components.tsx">
      {apiResults.map((item: Suggestion, index: number) => <li data-testid={`autocomplete-option-${index}`} className={classNames(styles.listItem, variant, {
      [styles.firstListItem]: index === 0
    })} key={item.placeId} {...getItemProps({
      item,
      index
    })}>
          <span className={classNames(styles.item, {
        [styles.itemHighlighted]: highlightedIndex === index
      })}>
            <span className={styles.iconWrapper}>
              <Svg type="search" name={getSvg(item.type)} data-testid={`icon-${item.type}`} />
            </span>
            <span className={styles.listItemText}>
              {renderSuggestedItem(item.label, searchTerm)}
            </span>
          </span>
        </li>)}
    </ul>;
};
export const NoMatches = ({
  searchTerm
}: {
  searchTerm: string;
}) => {
  return <div className={styles.errorWrapper} data-testid="no-matches-found" data-sentry-component="NoMatches" data-sentry-source-file="Components.tsx">
      <div className={styles.errorMessageMargin}>
        <Heading level={2} className={styles.noMatchesHeading} data-sentry-element="Heading" data-sentry-source-file="Components.tsx">
          No matches
        </Heading>
        <p className={styles.errorText}>
          We can't find any matches for '{searchTerm}'.
        </p>
        <p className={styles.errorText}>Maybe try a less specific location?</p>
      </div>
    </div>;
};
export const AddMoreLetters = ({
  searchTermLength
}: {
  searchTermLength: number;
}) => {
  return <div className={styles.errorWrapper} data-testid="add-more-text" data-sentry-component="AddMoreLetters" data-sentry-source-file="Components.tsx">
      <p className={styles.errorText}>
        <span className={styles.boldText}>
          Add {(searchTermLength - 3) * -1} more{' '}
          {`letter${searchTermLength < 2 ? 's' : ''}`}
        </span>{' '}
        to improve your search results.
      </p>
    </div>;
};
export const GeoError = () => {
  return <div className={classNames(styles.errorWrapper, styles.errorBackground)} data-testid="error-text" data-sentry-component="GeoError" data-sentry-source-file="Components.tsx">
      <Heading level={2} className={styles.errorHeading} data-sentry-element="Heading" data-sentry-source-file="Components.tsx">
        Oops! Something broke...
      </Heading>
      <p className={styles.errorText}>
        We couldn’t retrieve the geographical coordinates for this location.
      </p>
      <p className={styles.errorText}>Please try again later.</p>
    </div>;
};
export const GeneralError = () => {
  return <div className={classNames(styles.errorWrapper, styles.errorBackground)} data-testid="error-text" data-sentry-component="GeneralError" data-sentry-source-file="Components.tsx">
      <Heading level={2} className={styles.errorHeading} data-sentry-element="Heading" data-sentry-source-file="Components.tsx">
        Oops! Something broke...
      </Heading>
      <p className={styles.errorText}>We couldn’t retrieve any locations.</p>
      <p className={styles.errorText}>Please try again later.</p>
    </div>;
};