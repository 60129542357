import Suggestion from '../../types/Suggestion'

export function formatPopularCityCode(selection: Suggestion) {
  let selectionObj
  if (selection?.placeId?.startsWith('popular-')) {
    selectionObj = { ...selection }
    selectionObj.placeId = selectionObj?.placeId.replace('popular-', '').trim()
    return selectionObj
  }
  return selection
}
