import classNames from 'classnames';
import Svg from '../Svg';
import styles from './styles.module.scss';
interface CloseButtonProps {
  className?: string;
}
const ClearButton = ({
  className,
  ...props
}: CloseButtonProps) => {
  return <button type="button" aria-label="Clear" className={classNames(styles.clearButton, className)} {...props} data-sentry-component="ClearButton" data-sentry-source-file="index.tsx">
      <span className={styles.clearButtonInner}>
        <Svg type="utility" name="close" className={styles.clearIcon} data-sentry-element="Svg" data-sentry-source-file="index.tsx" />
      </span>
    </button>;
};
export default ClearButton;