import { useState, useEffect } from 'react'

type KeyboardVisibilityReturnType = {
  viewportHeight: number | undefined
}

const useDynamicIosViewportHeight = (
  mediaQuery?: string
): KeyboardVisibilityReturnType => {
  if (typeof window === 'undefined') {
    return { viewportHeight: undefined }
  }

  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight
  )

  const handleResize = () => {
    const visualViewportHeight = window.visualViewport?.height || 0
    setViewportHeight(visualViewportHeight)
  }

  useEffect(() => {
    const matchMedia = mediaQuery ? window.matchMedia(mediaQuery) : null

    const checkAndResize = () => {
      if (!matchMedia || matchMedia.matches) {
        handleResize()
      }
    }

    checkAndResize() // Run immediately if the condition matches

    window.addEventListener('resize', checkAndResize)
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', checkAndResize)
    }

    if (matchMedia) {
      matchMedia.addEventListener('change', checkAndResize)
    }

    return () => {
      window.removeEventListener('resize', checkAndResize)
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', checkAndResize)
      }
      if (matchMedia) {
        matchMedia.removeEventListener('change', checkAndResize)
      }
    }
  }, [mediaQuery])

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${viewportHeight}px`)
  }, [viewportHeight])

  return { viewportHeight }
}

export default useDynamicIosViewportHeight
