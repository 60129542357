const setItem = (id: string, value: any) => {
  let sessionValue = value

  if (typeof sessionValue === 'object') {
    sessionValue = JSON.stringify(sessionValue)
  }

  sessionStorage.setItem(id, sessionValue)
}

const getItem = (id: string) => {
  const value = sessionStorage.getItem(id)

  try {
    return JSON.parse(value as string)
  } catch (e) {
    return value
  }
}

const removeItem = (id: string) => {
  sessionStorage.removeItem(id)
}

const clear = () => {
  sessionStorage.clear()
}

export default {
  setItem,
  getItem,
  removeItem,
  clear,
}
