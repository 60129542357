import { useState, useEffect, useRef, RefObject } from 'react'

interface UseHasScrolledReturn<T extends HTMLElement> {
  ref: RefObject<T>
  hasScrolled: boolean
}

function useHasScrolledPast<T extends HTMLElement>(
  scrolledPixels: number
): UseHasScrolledReturn<T> {
  const ref = useRef<T | null>(null)
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const handleScroll = () => {
      requestAnimationFrame(() => {
        element.scrollTop > scrolledPixels
      })
    }

    element.addEventListener('scroll', handleScroll, { passive: true })

    return () => element.removeEventListener('scroll', handleScroll)
  }, [scrolledPixels])

  return { ref, hasScrolled }
}

export default useHasScrolledPast
