import React, { useState, useEffect, useRef, forwardRef, ChangeEvent, MutableRefObject } from 'react';
import classNames from 'classnames';
import { useCombobox } from 'downshift';
import Suggestion from '../../../types/Suggestion';
import CountryCode from '../../../types/CountryCode';
import useGoogleAutocompleteLoader from '../../../hooks/useGoogleAutocompleteLoader';
import { logSelectPopularCityInDropdown } from '../../../logging/popularCities.logging';
import { popularCitiesArr, updateAutocompleteOptions } from './helpers';
import { renderDropdownContent } from './Components';
import styles from './styles.module.scss';
export enum LocationAutocompleteVariant {
  HPMOBILE = 'hp-mobile',
  HPDESKTOP = 'hp-desktop',
  SEARCH = 'search',
  FORM = 'form',
}
export interface LocationAutocompleteFieldProps {
  variant: LocationAutocompleteVariant;
  className?: string;
  id?: string;
  name: string;
  initialValue: string;
  handleOnChange?: (arg: string) => void;
  handleOnBlur?: (arg: Suggestion | null | undefined) => void;
  handleOnSelect: (arg: Suggestion | null | undefined) => void;
  autocompleteApi: (countryCode: CountryCode, searchTerm: string) => any;
  countryCodeForAutocomplete: CountryCode;
  autoFocus?: boolean;
  showGeoCoordinatesError: boolean;
  clearGeoCoordinatesError: () => void;
  setCountry?: (arg: CountryCode) => void;
  renderInputWrapper: any;
  renderInput: any;
  renderClearButton: any;
  renderLabel: any;
  renderMenuContainer: any;
  isDesktop: boolean;
}
const LocationAutocompleteField = forwardRef<HTMLInputElement, LocationAutocompleteFieldProps>(({
  variant,
  className,
  id,
  name,
  initialValue,
  handleOnChange,
  handleOnBlur,
  handleOnSelect,
  autocompleteApi,
  countryCodeForAutocomplete,
  autoFocus,
  showGeoCoordinatesError,
  clearGeoCoordinatesError,
  setCountry,
  renderInputWrapper,
  renderInput,
  renderClearButton,
  renderLabel,
  renderMenuContainer,
  isDesktop
}, ref) => {
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [error, setError] = useState({
    noMatches: false,
    responseError: false
  });
  const [options, setOptions] = useState<Suggestion[]>([...popularCitiesArr(countryCodeForAutocomplete)]);
  const [isOpen, setIsOpen] = useState(false);
  const setNoMatches = (value: boolean) => setError({
    ...error,
    noMatches: value
  });
  const setResponseError = (value: boolean) => setError({
    ...error,
    responseError: value
  });
  const clearAllErrors = () => setError({
    noMatches: false,
    responseError: false
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    googleLoaded
  } = useGoogleAutocompleteLoader({
    language: 'en-GB',
    onError: () => {
      setError({
        ...error,
        responseError: true
      });
    }
  });
  useEffect(() => {
    if (inputRef && inputRef.current && autoFocus) {
      updateAutocompleteOptions(searchTerm, setOptions, googleLoaded, autocompleteApi, setNoMatches, setResponseError, countryCodeForAutocomplete, setCountry);
      inputRef.current.focus();
    }
  }, [autoFocus, googleLoaded]);
  useEffect(() => {
    setSearchTerm(initialValue);
  }, []);
  const onInputChange = (inputValue: string, locationHasBeenSelected: boolean) => {
    setSearchTerm(inputValue);
    if (handleOnChange) {
      handleOnChange(inputValue);
    }
    if (locationHasBeenSelected) return;
    updateAutocompleteOptions(inputValue, setOptions, googleLoaded, autocompleteApi, setNoMatches, setResponseError, countryCodeForAutocomplete, setCountry);
  };
  const onInputValueChange = (locationHasBeenSelected: boolean, inputValue?: string) => {
    clearAllErrors();
    clearGeoCoordinatesError();
    if (!inputValue) {
      setNoMatches(false);
      clearAllErrors();
    }
    onInputChange(inputValue ?? '', locationHasBeenSelected);
  };
  const onInputFocus = (inputValue: string) => {
    if (!inputValue) {
      clearAllErrors();
      clearGeoCoordinatesError();
    }
    if (variant === LocationAutocompleteVariant.SEARCH || variant === LocationAutocompleteVariant.FORM && searchTerm.length > 0) {
      updateAutocompleteOptions(inputValue, setOptions, googleLoaded, autocompleteApi, setNoMatches, setResponseError, countryCodeForAutocomplete, setCountry);
    }
    setIsOpen(true);
  };
  const handleClearInput = () => {
    setSearchTerm('');
    if (handleOnChange) {
      handleOnChange('');
    }
    clearAllErrors();
    setOptions([...popularCitiesArr(countryCodeForAutocomplete)]);
  };
  const onInputBlur = (inputValue: string) => {
    if (!inputValue) {
      handleClearInput();
    }
    setIsOpen(false);
  };
  const onClearButtonClick = () => {
    handleClearInput();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };
  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    isOpen,
    inputValue: searchTerm,
    id: id || name,
    items: options,
    defaultHighlightedIndex: 0,
    itemToString: item => item ? item.name : '',
    onSelectedItemChange: ({
      selectedItem,
      type
    }) => {
      const shouldSelectItem = type === useCombobox.stateChangeTypes.InputKeyDownEnter || type === useCombobox.stateChangeTypes.ItemClick;
      if (shouldSelectItem) {
        handleOnSelect(selectedItem);
        if (selectedItem?.isPopularCity) {
          logSelectPopularCityInDropdown(selectedItem.name.split(',')[0]);
        }
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }
      if (!shouldSelectItem && handleOnBlur) {
        handleOnBlur(selectedItem);
        if (selectedItem && inputRef.current) {
          inputRef.current.blur();
        }
      }
    },
    onInputValueChange: ({
      inputValue: inputVal,
      selectedItem
    }) => {
      const locationHasBeenSelected = inputVal === selectedItem?.name;
      onInputValueChange(locationHasBeenSelected, inputVal);
    },
    stateReducer: (state, actionAndChanges) => {
      const {
        type,
        changes
      } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            // Preserve the highlighted index
            isOpen: false
          };
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            highlightedIndex: 0
          };
        default:
          return changes;
      }
    }
  });
  const showAutoCompleteSuggestions = options.length > 0;
  const combinedRef = (el: HTMLInputElement | null) => {
    inputRef.current = el;
    if (ref && el) {
      ;
      (ref as MutableRefObject<HTMLInputElement | null>).current = el;
    }
  };
  return <div className={classNames(styles.field, {
    [styles.isDesktop]: isDesktop
  }, className)}>
        {renderInputWrapper({
      children: <>
              {renderInput({
          className: classNames({
            [styles.invalidInput]: showGeoCoordinatesError
          }),
          ...getInputProps({
            ref: combinedRef,
            value: searchTerm,
            name,
            type: 'search',
            role: 'searchbox',
            onBlur: (e: ChangeEvent<HTMLInputElement>) => {
              onInputBlur(e.target.value);
            },
            onFocus: () => {
              onInputFocus(searchTerm);
              if (!isOpen) {
                setIsOpen(true);
              }
            }
          })
        })}

              {renderLabel(getLabelProps())}

              {searchTerm && renderClearButton({
          onClick: () => {
            onClearButtonClick();
          },
          'aria-controls': getInputProps().id
        })}
            </>
    })}

        <div {...getMenuProps()} className={classNames(styles.menu, {
      [styles.menuOpen]: isOpen
    })} aria-hidden={!isOpen} data-testid="location-dropdown-menu">
          {renderMenuContainer({
        children: renderDropdownContent(searchTerm, options, highlightedIndex, getItemProps, variant, countryCodeForAutocomplete, error.noMatches, showAutoCompleteSuggestions, error.responseError, showGeoCoordinatesError)
      })}
        </div>
      </div>;
});
export default LocationAutocompleteField;