const getMatchedAndNotMatchedSubstrings = (
  string: string,
  searchTerm: string
) => {
  let bestMatch = {
    index: 0,
    length: 0,
  }

  const lowercasedString = string.toLowerCase()
  const lowercasedSearchTerm = searchTerm.toLowerCase()

  let i = 0
  let j = 0
  for (i = 0; i < string.length; i++) {
    for (j = 0; j < searchTerm.length; j++) {
      if (lowercasedString[i + j] !== lowercasedSearchTerm[j]) {
        break
      }
    }

    if (j > bestMatch.length) {
      bestMatch = {
        index: i,
        length: j,
      }
    }
  }

  let matchedSubstring = ''
  let notMatchedSubstringAtTheBeginning = ''
  let notMatchedSubstringAtTheEnd = ''

  const matchAtTheBeginningOfString = bestMatch.index === 0
  const matchAtTheEndOfString =
    bestMatch.index + bestMatch.length === string.length - 1
  const matchAtTheMiddleOfString =
    !matchAtTheBeginningOfString && !matchAtTheEndOfString

  if (matchAtTheBeginningOfString) {
    matchedSubstring = string.slice(0, bestMatch.length)
    notMatchedSubstringAtTheEnd = string.slice(bestMatch.length)
  }
  if (matchAtTheEndOfString) {
    matchedSubstring = string.slice(
      bestMatch.index,
      bestMatch.index + bestMatch.length
    )
    notMatchedSubstringAtTheBeginning = string.slice(0, bestMatch.index)
  }
  if (matchAtTheMiddleOfString) {
    matchedSubstring = string.slice(
      bestMatch.index,
      bestMatch.index + bestMatch.length
    )
    notMatchedSubstringAtTheBeginning = string.slice(0, bestMatch.index)
    notMatchedSubstringAtTheEnd = string.slice(
      bestMatch.index + bestMatch.length
    )
  }

  return {
    matchedSubstring,
    notMatchedSubstringAtTheBeginning,
    notMatchedSubstringAtTheEnd,
  }
}

export default getMatchedAndNotMatchedSubstrings
