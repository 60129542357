import { Dispatch, SetStateAction } from 'react';
import throttle from '../../../utils/throttle';
import getMatchedAndNotMatchedSubstrings from '../../../utils/search/getMatchedAndNotMatchedSubstrings';
import CountryCode from '../../../types/CountryCode';
import Suggestion from '../../../types/Suggestion';
import styles from './styles.module.scss';
import countryCode from '../../../types/CountryCode';
export const renderSuggestedItem = (label: Suggestion['label'], searchTerm: string) => {
  const {
    matchedSubstring,
    notMatchedSubstringAtTheBeginning,
    notMatchedSubstringAtTheEnd
  } = getMatchedAndNotMatchedSubstrings(label.mainText, searchTerm);
  return <span className={styles.suggestedItemText} data-sentry-component="renderSuggestedItem" data-sentry-source-file="helpers.tsx">
      <span>
        {notMatchedSubstringAtTheBeginning && <span>{notMatchedSubstringAtTheBeginning}</span>}
        <span className={styles.suggestedItemMatchedText}>
          {matchedSubstring}
        </span>
        {notMatchedSubstringAtTheEnd && <span>{notMatchedSubstringAtTheEnd}</span>}
      </span>
      {label.secondaryText && <span className={styles.suggestedItemSecondaryText}>
          {' '}
          {label.secondaryText}
        </span>}
    </span>;
};
const setOptionsFromApiSuggestions = (searchTerm: string, setOptions: (arg: Suggestion[]) => void, response: {
  success: any;
  suggestions: Suggestion[];
  country: any;
}, setNoMatches: (arg: boolean) => void, setCountry: (arg: CountryCode) => void) => {
  const isSuggestionsFound: boolean = response.success && response.suggestions.length > 0;
  const noOptionsReturned: boolean = response.success && response.suggestions.length === 0;
  if (isSuggestionsFound) {
    setNoMatches(false);
    setOptions(response.suggestions);
  }
  if (noOptionsReturned && searchTerm.length === 0) {
    setNoMatches(false);
    setOptions([]);
  }
  if (noOptionsReturned && searchTerm.length > 0) {
    setNoMatches(true);
    setOptions([]);
  }
  if (setCountry) {
    setCountry(response.country);
  }
};
const getSuggestionsFromApi = throttle(async (latestSearchTerm: string, setOptions: (arg: Suggestion[]) => void, autocompleteApi: (countryCode: CountryCode, searchTerm: string) => any, setNoMatches: (arg: boolean) => void, setResponseError: (arg: boolean) => void, setCountry: () => void, countryCode: CountryCode) => {
  const response = await autocompleteApi(countryCode, latestSearchTerm);
  if (response.success) {
    setOptionsFromApiSuggestions(latestSearchTerm, setOptions, response, setNoMatches, setCountry);
  } else setResponseError(true);
}, 1000);
export const updateAutocompleteOptions = (inputValue: string, setOptions: Dispatch<SetStateAction<Suggestion[]>>, googleLoaded: boolean, autocompleteApi: (countryCode: CountryCode, searchTerm: string) => any, setNoMatches: (value: boolean) => void, setResponseError: (value: boolean) => void, countryCodeForAutocomplete: CountryCode, setCountry?: (country: CountryCode) => void) => {
  if (!inputValue || inputValue.length < 3) {
    setOptions([...popularCitiesArr(countryCodeForAutocomplete)]);
    return;
  }
  if (!googleLoaded) return;
  getSuggestionsFromApi(inputValue, (apiOptions: Suggestion[]) => {
    const mergedOptions = [...apiOptions, ...popularCitiesArr(countryCodeForAutocomplete)];
    setOptions(mergedOptions);
  }, autocompleteApi, setNoMatches, setResponseError, setCountry, countryCodeForAutocomplete);
};
export function getSvg(type: Suggestion['type']) {
  switch (type) {
    case 'city':
      return 'city';
    case 'university':
      return 'university';
    case 'location':
      return 'location';
    default:
      return 'location';
  }
}
export const popularCitiesList = [{
  city: 'London',
  googlePlaceId: 'ChIJdd4hrwug2EcRmSrV3Vo6llI'
}, {
  city: 'Manchester',
  googlePlaceId: 'ChIJ2_UmUkxNekgRqmv-BDgUvtk'
}, {
  city: 'York',
  googlePlaceId: 'ChIJ8WWY4UDDeEgR0eRUiomrdEc'
}, {
  city: 'Liverpool',
  googlePlaceId: 'ChIJt2BwZIrfekgRAW4XP28E3EI'
}, {
  city: 'Birmingham',
  googlePlaceId: 'ChIJc3FBGy2UcEgRmHnurvD-gco'
}, {
  city: 'Nottingham',
  googlePlaceId: 'ChIJzXkHOdIyeEgRFdsZGSBjgBA'
}, {
  city: 'Bristol',
  googlePlaceId: 'ChIJYdizgWaDcUgRH9eaSy6y5I4'
}, {
  city: 'Newcastle',
  googlePlaceId: 'ChIJAZuMSvgOYUgRjAPlGBbSkvE'
}, {
  city: 'Exeter',
  googlePlaceId: 'ChIJp41J1MRSbEgRHq3fayXjdqk'
}, {
  city: 'Leicester',
  googlePlaceId: 'ChIJc2y3SatCd0gRuJy0byodFZo'
}];
export const popularCitiesArr = (countryCode: CountryCode): Suggestion[] => popularCitiesList.map((item: {
  city: string;
  googlePlaceId: string;
}) => ({
  placeId: `popular-${item.googlePlaceId}`,
  name: `${item.city}${countryCode === 'afsCountries' ? ', UK' : ''}`,
  type: 'city' as Suggestion['type'],
  label: {
    mainText: item.city,
    secondaryText: 'UK'
  },
  country: 'UK',
  isPopularCity: true
}));